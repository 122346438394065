import {useState} from "react";
import TopBarDropdown from '../TopBarDropdown';
import TopBarUserDropDown from "./TopBarUserDropDown";
import './TopBarUserRightDesktop.scss';

const TopBarUserRightDesktop = (
    {
        className,
        userName,
        logoutAction
    }: {
        className?: string;
        userName: string;
        logoutAction: () => void;
    }
) => {
    const [showUserDropdown, setShowUserDropdown] = useState(false);

    return (
        <div className={`${!!className ? ` ${className}` : ''}`}
             style={{
                 whiteSpace: 'nowrap'
             }}
             onClick={() => setShowUserDropdown(!showUserDropdown)}
        >
            <TopBarDropdown buttonNode={
                                <div className='p-clickable'>
                                    <div className='level p-user-name'>
                                        { userName }
                                        <div className='caret'/>
                                    </div>
                                </div>
                            }
                            expandNode={(showUserDropdown &&
                                <TopBarUserDropDown logoutAction={logoutAction}/>) || null}/>
        </div>
    );
}

export default TopBarUserRightDesktop;
