import {ReactNode} from "react";
import ILoadable from "./ILoadable.ts";
import {AccountInfo} from "@azure/msal-browser";
import Loader from "./Loader.tsx";

const AuthWrapper = (
    {
        account,
        onLoading,
        children
    }: {
        account: ILoadable<AccountInfo>;
        onLoading?: () => ReactNode;
        children: (account: AccountInfo) => ReactNode;
    }
) => {
    const accountLoaded = account.loaded;
    return !!accountLoaded ? children(accountLoaded) : (onLoading ?? (() => <Loader/>))();
}

export default AuthWrapper;