import GoBack from 'factor-lib/GoBack';
import TopBar from 'factor-lib/TopBar/TopBar';
import {DashboardInvoicesDefaultRoute} from "../Pages/Dashboard/dashboardUrlsConsts";
import {AccountInfo} from "@azure/msal-browser";
import ITopBarProps from './ITopBarProps';
import ILoadable, {mapLoadable} from "factor-lib/ILoadable.ts";
import {navigateVoid} from "factor-lib/contexts/navigateWrapper.ts";
import TopBarUserRightDesktop from "factor-lib/TopBar/CustomerTopbar/TopBarUserRightDesktop.tsx";
import ReactQueryAuthWrapper from "factor-lib/reactquery/ReactQueryAuthWrapper.tsx";
import {getCustomerContext} from "../contexts/ICustomerContext.ts";
import {axiosGet} from "factor-lib/authServer/axiosWrapper.ts";
import {ReactQueryNoNeedRefresh} from "factor-lib/reactquery/utils.ts";

export interface IAuth {
    account: AccountInfo;
    logoutAction: () => void;
}

interface ISelf {
    firstName: string;
    lastName: string;
}

const RightMenu = (
    {
        authO
    }: {
        authO: ILoadable<IAuth>;
    }
) => {
    const authLoaded = authO.loaded;
    // Ideally, but always undefined
    // const idTokenClaims = authLoaded?.account.idTokenClaims;
    return (
        !!authLoaded && /* !!idTokenClaims && */ // accountsO.length > 0 /* On peut etre appele sans etre authentifie juste avant la redirection */ &&
            // <TopBarUserRightDesktop className='is-hidden-mobile level-right p-margin-right-5'
            //                         userName={authLoaded.account.name! /* Always set in our case. /!\ username is the email */}
            //                         logoutAction={authLoaded.logoutAction}/>

        // <TopBarUserRightDesktop className='is-hidden-mobile level-right p-margin-right-5'
        //                         // eslint-disable-next-line  @typescript-eslint/restrict-template-expressions
        //                         userName={`${idTokenClaims.given_name} ${idTokenClaims.family_name}` /* authLoaded.account.name! */  /* Always set in our case. /!\ username is the email */}
        //                         logoutAction={authLoaded.logoutAction}/>

            <ReactQueryAuthWrapper<ISelf>
                    account={mapLoadable(authO, (a) => a.account)}
                    onLoading={() => null}
                    queryKey={['self']}
                    queryFunc={(account) => {
                        // Does not work.
                        // return axiosGet(
                        //     "User.Read",
                        //     "https://graph.microsoft.com/v1.0/me",
                        //     account
                        // );
                        const commonCustomerBackend = getCustomerContext().backends.commonCustomer;
                        return axiosGet<ISelf>(
                            commonCustomerBackend.adScope,
                            `${commonCustomerBackend.baseUrl}/auth`,
                            account
                        );
                    }}
                    options={ReactQueryNoNeedRefresh} >
                { (response) =>
                    <TopBarUserRightDesktop className='is-hidden-mobile level-right p-margin-right-5'
                                            userName={`${response.firstName} ${response.lastName}` /* authLoaded.account.name! */  /* Always set in our case. /!\ username is the email */}
                                            logoutAction={authLoaded.logoutAction}/>
                }
            </ReactQueryAuthWrapper>
    );
}

const FactorTopBar = (
    {
        topBarProps,
        authO
    }: {
        topBarProps: ITopBarProps | null;
        authO: ILoadable<IAuth>;
    }
) =>
    <TopBar style={{marginBottom: '0' /* Default margin of topbar due to level is-marginless from old bulma has disappeared*/}}
            logoClick={() => navigateVoid(DashboardInvoicesDefaultRoute)}
            leftExtra={(!!topBarProps?.goBackActionO &&
                <GoBack action={topBarProps.goBackActionO} />) || null
            }
            title={topBarProps?.titleO ?? null}
            rightMenu={
                <RightMenu authO={authO}/>
            } />;

export default FactorTopBar;
