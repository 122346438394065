import './topbardropdown.scss';
import {ReactNode} from "react";

// TODO : merge with TopToolTip
const TopBarDropdown = (
    {
        buttonNode,
        expandNode
    }: {
        buttonNode: ReactNode;
        expandNode: ReactNode | null;
    }
) =>
    <div className='p-dtbdd-wrapper'>
        { buttonNode }
        { !!expandNode &&
            <div className='dropdown-container'>
                <div className='p-dropdown'>
                    { expandNode }
                </div>
            </div>
        }
    </div>;

export default TopBarDropdown;
