import {ReactElement, useMemo, useState} from "react";
import ITopBarProps from "./ITopBarProps";
import FactorTopBar, {IAuth} from "./FactorTopBar";
import ILoadable from "factor-lib/ILoadable.ts";

const TopBarWrapper = (
    {
        authO,
        children
    }: {
        authO: ILoadable<IAuth>;
        children: (safeSetTopBarProps: (newTopBarProps: ITopBarProps) => void) => ReactElement;
    }
) => {
    const [topBarProps, setTopBarProps] = useState<ITopBarProps | null>(null);

    // Necessary to avoid infinite loop
    const childMemo = useMemo(
        () => children(setTopBarProps),
        [children]
    );

    return (
        <>
            <FactorTopBar topBarProps={topBarProps}
                          authO={authO} />
            { childMemo }
        </>
    )
};

export default TopBarWrapper;