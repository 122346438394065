import Loader from "../Loader.tsx";
import ReactQueryWrapper from "./ReactQueryWrapper.tsx";
import ILoadable from "../ILoadable.ts";
import {AccountInfo} from "@azure/msal-browser";
import {ReactElement, ReactNode} from "react";
import {QueryKey, UseQueryOptions} from "@tanstack/react-query";
import AuthWrapper from "../AuthWrapper.tsx";

const ReactQueryAuthWrapper = <T, > (
    {
        account,
        onLoading,
        onError,
        queryKey,
        queryFunc,
        options,
        children
    }: {
        account: ILoadable<AccountInfo>;
        onLoading?: () => ReactNode;
        onError?: (e: string) => ReactElement; // TODO: make required?
        queryKey: QueryKey;
        queryFunc: (account: AccountInfo, signal?: AbortSignal) => Promise<T>;
        options?: Omit<
            UseQueryOptions<T>,
            'queryKey' | 'queryFn'
        >;
        children: (result: T, account: AccountInfo) => ReactNode;
    }
) =>
    <AuthWrapper account={account}
                 onLoading={onLoading}
    >
        { (a) =>
            <ReactQueryWrapper<T> queryKey={queryKey}
                                  queryFunc={() => queryFunc(a)}
                                  options={options}
                                  onError={onError}
                                  onLoading={() =>
                                      (onLoading ?? (() => <Loader/>))()
                                  }
            >
                { (r: T) => children(r, a) }
            </ReactQueryWrapper>
        }
    </AuthWrapper>;

export default ReactQueryAuthWrapper;