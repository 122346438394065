import TopBarWrapper from "./TopBar/TopBarWrapper.tsx";
import ITopBarProps from "./TopBar/ITopBarProps.ts";
import {IAuth} from "./TopBar/FactorTopBar.tsx";
import {ReactElement} from "react";
import ILoadable from "factor-lib/ILoadable.ts";

const GlobalLayout = (
    {
        authO,
        children
    }: {
        authO: ILoadable<IAuth>;
        children: (safeSetTopBarProps: (newTopBarProps: ITopBarProps) => void) => ReactElement;
    }
) =>
    <TopBarWrapper authO={authO}>
        { children }
    </TopBarWrapper>;

export default GlobalLayout;