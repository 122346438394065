import ICustomerBackends from "./ICustomerBackends.ts";
import {throwUnknownContextError} from "factor-lib/contexts/ILibFactorContext.ts";

// different from currentEnv, as does not rely on implementations
export default interface ICustomerContext {
    backends: ICustomerBackends;
    bankinUrl: string;
}

let wrapper: ICustomerContext | null = null;

export const setCustomerContext = (
    backends: ICustomerBackends,
    bankinUrl: string
): void => {
    wrapper = ({
        backends,
        bankinUrl
        // gtm,
        // intercom
    });
}

export const getCustomerContext = (): ICustomerContext =>
    wrapper ?? throwUnknownContextError();