// TODO: ideally split into two, but will need to propagate a generic
export enum InvoiceSortProperty {
    CreationDate = 'creationDateTime',
    Number = 'number',
    AmountWoTax = 'amountWoTax',
    AmountTTC = 'amountTTC',
    DueDate = 'dueDate',
    BuyerName = 'buyerName',
    SellerName = 'sellerName'
}

export const InvoiceSortPropertyToOrderByField: Map<InvoiceSortProperty, string> = new Map<InvoiceSortProperty, string>([
    [ InvoiceSortProperty.CreationDate, 'CREATION_DATE_TIME' ],
    [ InvoiceSortProperty.Number, 'NUMBER' ],
    [ InvoiceSortProperty.AmountWoTax, 'AMOUNT_WO_TAX' ],
    [ InvoiceSortProperty.AmountTTC, 'AMOUNT_TTC' ],
    [ InvoiceSortProperty.DueDate, 'DUE_DATE' ],
    [ InvoiceSortProperty.BuyerName, 'BUYER_NAME' ],
    [ InvoiceSortProperty.SellerName, 'SELLER_NAME' ]
]);
