export const GoBackId = 'GoBack';

import { ReactComponent as LeftArrowBack } from './LeftArrowBack.svg';

const GoBack = (
    {
        action
    }: {
        action: () => void;
    }
) =>
    <div id={GoBackId}
         className='p-horizontal-center p-clickable'
         onClick={action/* () => target ? $router.push(target) : $router.back() */}>
        <LeftArrowBack />
        <span className='p-margin-left-4 is-uppercase'>Retour</span>
    </div>;

export default GoBack;
