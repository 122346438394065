
// different from currentEnv, as does not rely on implementations
import {IBackendOAuth2Service} from "../authServer/IBackendOAuth2Service.ts";
import {throwUnknownContextError} from "./ILibFactorContext.ts";

let wrapper: IBackendOAuth2Service | null = null;

export const setOAuth2Context = (
    backendOAuth2Service: IBackendOAuth2Service
): void => {
    wrapper = backendOAuth2Service;
}

export const getOAuth2Context = (): IBackendOAuth2Service =>
    wrapper ?? throwUnknownContextError();