import './TopBarUserDropDown.scss';

const TopBarUserDropDown = (
    {
        logoutAction
    }: {
        logoutAction: () => void;
    }
) =>
    <div className='p-padding-6'>
        <div className='logout'
             onClick={logoutAction}>
            Se déconnecter
        </div>
    </div>;

export default TopBarUserDropDown;
