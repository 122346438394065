import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom/client';
// import reportWebVitals from './reportWebVitals';
import Loader from "factor-lib/Loader.tsx";
// import HttpClientContext from 'factor-lib/components/HttpClientContext';

import GlobalLayout from "./GlobalLayout.tsx";

import {Loading} from "factor-lib/ILoadable.ts";

// Doit etre en dernier, sinon ne marche pas
import './index.scss';

// const debug = CurrentEnv.debug;

const MainRouterAndQueryWrapperLazy = lazy(() => import('./MainRouterAndQueryWrapper'));

ReactDOM.createRoot(document.getElementById('root')!)
    .render(
        <React.StrictMode>
            <Suspense fallback={
                <GlobalLayout authO={Loading}>
                    {() => <Loader/> }
                </GlobalLayout>
            }>
                <MainRouterAndQueryWrapperLazy />
            </Suspense>
        </React.StrictMode>
    );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
