import {comparingRev, comparingString} from "./comparingUtils";

export enum SortOrder {
    Asc = 'asc',
    Desc = 'desc'
}

export interface ISort<I> {
    property: I;
    order: SortOrder;
}

export const SortOrderToOrderByDirection: Map<SortOrder, string> = new Map<SortOrder, string>([
    [ SortOrder.Asc, 'ASC' ],
    [ SortOrder.Desc, 'DESC' ]
]);

const idComparator = comparingString<string>((id: string) => id);

export const buildSortFinal = <T,>(
    sortAsc: (t1: T, t2: T) => number,
    sortOrder: SortOrder,
    idExtractor: (t: T) => string
): (t1: T, t2: T) => number => {

    const sort = sortOrder === SortOrder.Asc
        ? sortAsc
        : comparingRev(sortAsc);

    return (t1: T, t2: T): number => {
        const c = sort(t1, t2);

        if (c !== 0) {
            return c;
        }
        // else

        const t1Id = idExtractor(t1);
        const t2Id = idExtractor(t2);

        // TODO : remove curification ?
        return sortOrder === SortOrder.Asc
            ? idComparator(t1Id, t2Id)
            : idComparator(t2Id, t1Id);
    };
};