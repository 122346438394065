export const comparingNumber = <T>(extractor: (e: T) => number) =>
    (i1: T, i2: T): number => {
        const v1: number = extractor(i1);
        const v2: number = extractor(i2);
        return v1 == v2 ? 0 : (v1 < v2 ? -1 : 1);
    };


// use that in comparingDate
// export const comparingDate = <T>(extractor: (e: T) => Date) =>
//     (i1: T, i2: T): number => {
//         const v1: Date = extractor(i1);
//         const v2: Date = extractor(i2);
//         return v1 == v2 ? 0 : (v1 < v2 ? -1 : 1);
//     };


export const comparingString = <T>(extractor: (e: T) => string) =>
    (i1: T, i2: T): number => {
        const v1: string = extractor(i1);
        const v2: string = extractor(i2);
        return v1 == v2 ? 0 : (v1 < v2 ? -1 : 1);
    };

export const comparingRev = <T>(baseComparator: (e1: T, e2: T) => number) =>
    (i1: T, i2: T): number => baseComparator(i2, i1);

export const comparingMultiple = <T>(
    // uses all the comparators sequentially, until a strict comparison is found
    comparators: ((i1: T, i2: T) => number)[]
): (i1: T, i2: T) => number =>
    (i1, i2) => {
        for (const comparator of comparators) {
            const result: number = comparator(i1, i2);
            if (result !== 0) {
                return result;
            }
        }
        return 0;
    }